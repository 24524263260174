// markup
import * as React from "react";
import "./index.scss";

const PrivacyPage = () => {
    return (
        <main className="main-page">
            <div className="document-body">
                <h1>POLÍTICA DE PRIVACIDAD</h1>
                <h2>
                    1. Identificación del responsable del tratamiento y datos de
                    contacto
                </h2>
                <p>
                    La presente Política de Privacidad se ha elaborado de
                    conformidad con el Reglamento General de Protección de Datos
                    de la UE 2016/679 del Parlamento Europeo y del Consejo, de
                    27 de abril de 2016, relativo a la protección de las
                    personas físicas en lo que respecta al tratamiento de datos
                    personales y a la libre circulación de estos datos y por el
                    que se deroga la Directiva 95/46/CE (Reglamento general de
                    protección de datos), y la Ley Orgánica 3/2018 del 5 de
                    diciembre, de Protección de Datos de Carácter Personal y
                    Garantía de los Derechos Digitales. Le informamos de que el
                    responsable del tratamiento de sus datos personales es
                    REDPYV, S.L., (en adelante, el “Titular”), con N.I.F.:
                    B67775585 y domicilio en Paseo de España, 16 - Piso 4 B,
                    Jaén, 23009. El acceso y/o uso de la aplicación móvil (en
                    adelante, la “Plataforma”), le atribuye la condición de
                    usuario (en adelante, el “Usuario” o los “Usuarios”) del
                    mismo e implica la aceptación, desde dicho acceso y/o uso,
                    de la presente Política de Privacidad. Si tiene alguna duda,
                    el Usuario podrá contactar con el Titular en la siguiente
                    dirección de correo electrónico:
                    __redpyv@appquickmoney_________
                </p>
                <h2>2. Categorías de datos</h2>
                <p>
                    Los datos recabados a través de la Plataforma corresponden a
                    la categoría de datos identificativos de contacto, tales
                    como: Nombre y Apellidos, Teléfono y Correo electrónico.
                </p>
                <h2>3. Legitimación para el tratamiento</h2>
                <p>
                    El Titular está legitimado para tratar sus datos de contacto
                    en base al Interés legítimo del Titular para atender a los
                    requerimientos de información por parte de los Usuarios a
                    través de la Plataforma, así como para poder prestarles sus
                    servicios.
                </p>
                <h2>4. Finalidades del tratamiento</h2>
                <p>
                    datos de carácter personal recabados por el Titular podrán
                    ser utilizados para las siguientes finalidades:
                </p>
                Sus
                <ul>
                    <li>
                        Gestionar las consultas planteadas a través del correo
                        electrónico o Plataforma.
                    </li>
                    <li>
                        Prestar los servicios solicitados en base a una relación
                        contractual.Almacenar o acceder a información de
                        geolocalización y páginas webs visitadas con el
                        propósito de realizar una publicidad dirigida.
                    </li>
                    <li>
                        -Almacenar o acceder a la información de un dispositivo
                        para su identificación.
                    </li>
                </ul>
                <h2>5. Conservación de los datos</h2>
                <p>
                    Los datos personales proporcionados se conservarán por parte
                    del Titular hasta la resolución de su solicitud de
                    información. En aquellos casos en los que el usuario
                    mantenga, tras el envío de su correo electrónico, una
                    relación contractual o informativa con el Titular, los datos
                    se conservarán mientras que el Usuario no manifieste su
                    intención de darse de baja. Posteriormente, en caso de ser
                    necesario, el Titular mantendrá la información bloqueada los
                    plazos legalmente establecidos. En aquellos casos en los que
                    no medie relación contractual o informativa alguna entre el
                    Usuario y el Titular una vez enviado el correo electrónico,
                    todos los datos personales consignados serán destruidos en
                    el plazo de dos meses, pudiendo el Usuario ejercitar su
                    derecho de acceso durante dicho plazo, antes de su
                    destrucción, salvo consentimiento expreso por el Usuario
                    para su tratamiento con otra finalidad distinta que la de
                    recibir información, como por ejemplo, en caso de tratarse
                    consentidamente con fines comerciales.
                </p>
                <h2>6. Destinatarios</h2>
                <p>
                    Sus datos solo serán tratados por el Titular para la
                    adecuada prestación de los servicios ofrecidos en la
                    Plataforma. No se prevén cesiones de datos ni transferencias
                    internacionales de datos, a excepción de las autorizadas por
                    la legislación fiscal, mercantil y de telecomunicaciones,
                    así como en aquellos casos en los que una autoridad judicial
                    lo requiera. 
                </p>
                <h2>7. Veracidad de los datos facilitados por el Usuario </h2>
                <p>
                    El Usuario garantiza que los datos personales facilitados
                    son veraces y se hace responsable de comunicar al Titular
                    cualquier modificación de estos. El Usuario responderá, en
                    cualquier caso, de la veracidad de los datos facilitados,
                    reservándose el Titular el derecho a excluir de los
                    servicios registrados a todo Usuario que haya facilitado
                    datos falsos, sin perjuicio de las demás acciones que
                    procedan en Derecho. 3
                </p>
                <h2>8. Derechos en relación con sus datos</h2>
                <p>El Usuario tiene derecho a: </p>
                <ul>
                    <li>(i) Acceder a sus datos personales.</li>
                    <li>
                        (ii) Solicitar la rectificación de los datos inexactos
                    </li>
                    <li> (iii) Solicitar su supresión.</li>
                    <li>
                        (iv) Solicitar la limitación del tratamiento de sus
                        datos.
                    </li>
                    <li> (v) Oponerse al tratamiento de sus datos.</li>
                    <li> (vi) Solicitar su portabilidad.</li>
                    <li>
                        (vii) No ser objeto de decisiones individuales
                        automatizadas.
                    </li>
                </ul>
                <p>
                    El Usuario puede ejercer todos estos derechos en la
                    siguiente dirección de email redpyv@appquickmoney__indicando
                    el motivo de su petición y aportando una copia de su D.N.I.
                    Asimismo, le informamos de que tiene derecho a presentar una
                    reclamación ante la Agencia Española de Protección de Datos
                    si considera que se ha cometido una infracción de la
                    legislación en materia de protección de datos respecto al
                    tratamiento de sus datos personales.
                </p>
                <h2>9. Seguridad de los datos</h2>
                <p>
                    El Titular ha adoptado los niveles de seguridad requeridos
                    por la normativa aplicable adecuados a la naturaleza de los
                    datos que son objeto de tratamiento en cada momento y ha
                    establecido todos los medios técnicos a su alcance para
                    evitar la pérdida, mal uso, alteración, acceso no autorizado
                    y robo de los datos que el Usuario facilite a través de la
                    Plataforma, sin perjuicio de informarle de que las medidas
                    de seguridad en Internet no son inexpugnables. El Titular se
                    compromete a cumplir con el deber de secreto y
                    confidencialidad respecto de los datos personales de acuerdo
                    con la legislación aplicable.
                </p>
                <h2>10. Revisión de la Política de Privacidad</h2>
                <p>
                    El Titular se reserva el derecho de revisar su Política de
                    Privacidad en el momento que considere oportuno. Por esta
                    razón, le rogamos que compruebe de forma regular esta
                    Política de Privacidad para leer la versión más reciente de
                    la misma. 4 No obstante, cualquier cambio que se produzca en
                    la presente Política de Privacidad se comunicará al Usuario.
                </p>
                <h2> 11. Links a terceros</h2>
                <p>
                    La Plataforma podría contener links a páginas web de
                    compañías y entidades de terceros. El Titular no puede
                    hacerse responsable de la forma en la que estas compañías
                    tratan la protección de la privacidad y de los datos
                    personales, por lo que le aconsejamos que lea detenidamente
                    las Políticas de Privacidad de estas páginas web que no son
                    propiedad del Titular con relación al uso, procesamiento y
                    protección de datos personales.
                </p>
                <h2> 12. Aceptación y Consentimiento.</h2>
                <p>
                    El Usuario declara haber sido informado de las condiciones
                    sobre protección de datos personales, aceptando y
                    consintiendo el tratamiento de estos por parte del Titular,
                    en la forma y para las finalidades indicadas en la presente
                    Política de Privacidad.
                </p>
            </div>
        </main>
    );
};

export default PrivacyPage;
